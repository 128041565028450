<template>
  <div class="about">
    <!-- <Navbar /> -->
    <!-- 唇釉 -->
    <div>
      <div class="box-list">
        <div
          ref="box"
          class="box-item"
          v-for="(item,index) in imgRes"
          :key="index"
        >
          <div
            :class="isActive===index?'active bg-box':'bg-box'"
            :style="{height:heightNum+'px'}"
            @mouseenter="handleEnter(index)"
            @mouseleave="handleLeave"
            @click="handleRoute(item.id)"
          >
            <div
              class="bg default"
              :style="{background:'url('+item.defalut+') no-repeat center',backgroundSize:'cover'}"
            ></div>

            <div
              class="bg active"
              :style="{background:'url('+item.active+') no-repeat center',backgroundSize:'cover'}"
            ></div>
            <!-- v-lazy:background-image="{src: item.active}" -->

            <div
              class="btn"
              @click="handleRoute(item.id)"
            >Learn More</div>
            <div class="desc">
              <div class="name">{{item.enName}}</div>
              <div class="cname">{{item.zhName}}</div>
              <div class="info">{{item.intro}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Navbar from '@/components/Navbar.vue'
import { blusherDetail } from './detail'

export default {
  name: 'about',
  components: {
    Navbar
  },
  data() {
    return {
      isActive: '',
      widthNum: 0,
      imgRes: []
    }
  },
  computed: {
    heightNum() {
      return this.widthNum * 0.94
    }
  },
  methods: {
    handleEnter(i) {
      this.isActive = i
    },
    handleLeave(i) {
      this.isActive = ''
    },
    handleRoute(id) {
      const name = '腮红'
      this.$router.push({ name: 'detail', query: { id, name } })
    },
  },
  created() {
    this.imgRes = blusherDetail
  },
  mounted() {
    this.widthNum = this.$refs.box[0].clientWidth
    window.onresize = () => {
      this.widthNum = this.$refs.box[0].clientWidth
    }
  }
}
</script>

<style lang="scss" scoped>
.box-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  .box-item {
    cursor: pointer;
    width: 50%;
    .bg-box {
      position: relative;
      overflow: hidden;
      width: 100%;
      .bg {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: cover;
      }
      .bg.default {
        opacity: 1;
        // transition: 0.2s;
      }
      .default[lazy="loading"] {
        width: 100px;
        height: 100px;
        // margin: 0 auto;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      .default[lazy="loaded"] {
        width: 100%;
      }
      .bg.active {
        transform: scale(1.15);
        opacity: 0;
        transition: 0.6s ease-out;
      }
      // .bg.active[lazy="loading"] {
      //   width: 30px;
      //   height: 30px;
      //   margin: 0 auto;
      //   top: 50%;
      //   left: 50%;
      // }
      // .bg.active[lazy="loaded"] {
      //   width: 100%;
      // }
      .btn {
        position: absolute;
        right: 2.375rem;
        bottom: 1.938rem;
        width: 8.313rem;
        height: 2.063rem;
        line-height: 2.063rem;
        text-align: center;
        font-size: 0.75rem;
        color: #fff;
        border: 1px solid #fff;
        box-sizing: border-box;
        cursor: pointer;
        transition: 0.4s;
      }
      .desc {
        position: absolute;
        bottom: 1.875rem;
        left: 2.188rem;
        color: #fff;
        .name {
          font-size: 1.8rem;
        }
        .cname {
          margin: 1.063rem 0 0.375rem;
          font-size: 1.125rem;
        }
        .info {
          font-size: 0.75rem;
        }
      }
    }
    .bg-box.active {
      .bg.default {
        opacity: 0;
      }
      .bg.active {
        transform: scale(1.03);
        opacity: 1;
      }
      .btn {
        background-color: #fff;
        color: #000;
      }
    }
  }
}
</style>